<template>
	<div>
		<a-config-provider :locale="zh_CN">
			<a-card>
				<a-row style="margin-bottom: 20px;">
					<a-col :span="10">
						<a-button type="primary" @click="createOrEdit('create')" v-if="isGranted('audit_level_create')">
							<a-icon type="plus" />
							{{l('Create')}}
						</a-button>
						<!-- <a-button type="primary" @click="createOrEditMachineSupplier('edit')">
							<a-icon type="edit" />
							修改
						</a-button> -->
						<a-button type="danger" @click="batchdelete" v-if="isGranted('audit_level_delete')">
							<a-icon type="delete" />
							{{l('Delete')}}
						</a-button>
					</a-col>

					<a-col :span="14" class="d-flex justify-content-end">
						<a-input-search :placeholder="l('please_enter_a_name_to_search')" v-model.trim="filterText" enterButton
							style="width: 200px;margin-left: 10px;" @search="filteronchange"></a-input-search>
					</a-col>
				</a-row>
				<a-spin :spinning="isLoading">
					<a-table :columns="columns" :data-source="tableData" :customRow="customRow"
						:row-selection="{selectedRowKeys:selectedRowKey,onSelect:onSelect,onSelectAll:SelectAll,columnWidth:'50px' }"
						:rowKey="(record)=>record.id" :scroll="{x:800,y:610}" :pagination="false" @change="handleTableChange" size="small">
						<span slot="action" slot-scope="text,record">
							<a @click.stop="createOrEditMachineSupplier(record)" v-if="isGranted('audit_level_edit')">
								<a-icon type="edit" />{{l('Edit')}}
							</a>
							<font v-if="isGranted('audit_level_delete')&&isGranted('audit_level_edit')"> | </font>
							<a @click.stop="deletetable(record)" v-if="isGranted('audit_level_delete')">
								<a-icon type="delete" />{{l('Delete')}}
							</a>
						</span>
					</a-table>
				</a-spin>
				<a-pagination class="pagination" :total="totalItems" showSizeChanger showQuickJumper
					:show-total="total => `共有 ${totalItems} 条`"
					:defaultPageSize="request.maxResultCount" :pageSizeOptions="pageSizeOptions"
					@change="onChangePage" @showSizeChange="showSizeChange" />
				<!-- <a-pagination
				        class="pagination"
				        :total="totalItems"
				        showSizeChanger
				        showQuickJumper
				        :showTotal="showTotalFun"
				        @change="onChangePage"
				        @showSizeChange="showSizeChange"/> -->
			</a-card>
		</a-config-provider>
	</div>
</template>

<script>
	import {
		AppComponentBase
	} from "@/shared/component-base";
	import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
	import {
		LayoutLevelServiceProxy
	} from '../../../shared/service-proxies';
	import {
		ModalHelper
	} from '@/shared/helpers';
	import CreatErrorCode from "./new-level/new-level.vue"

	let _this;
	export default {
		name: "audit-level",
		mixins: [AppComponentBase],
		components: {

		},
		created() {
			_this = this;
			this.LayoutLevelServiceProxy = new LayoutLevelServiceProxy(this.$apiUrl, this.$api);
		},
		mounted() {
			this.getData()
		},
		data() {
			return {
				zh_CN,
				isEdit: false,
				isLook: false,

				filterText: undefined,
				totalItems: 0, //总数
				// 当前页码
				pageNumber: 1,
				// 共多少页
				totalPages: 1,
				// 条数显示范围
				pagerange: [1, 1],
				// 显示条数
				pageSizeOptions: ["10", "20", "50", "100", "500"],
				request: {
					maxResultCount: 20,
					skipCount: 0
				},

				tableData: [],
				columns: [{
						title: this.l('layoutlevel.levelindex'),
						width: 80,
						dataIndex: 'levelIndex',
						align: 'center',
						sorter:true
					},
					{
						title: this.l('Name'),
						width: 200,
						dataIndex: 'name',
						align: 'center',
						sorter:true
					},
					{
						title: this.l('layoutlevel.msginterval'),
						width: 150,
						dataIndex: 'msgInterval',
						align: 'center',
						sorter:true
					},
					{
						title: this.l('Remark'),
						dataIndex: 'remark',
						align: 'left',
						ellipsis: true
					},
					{
						title: this.l('Actions'),
						width: 150,
						dataIndex: 'action',
						align: 'center',
						scopedSlots: {
							customRender: "action"
						},
						fixed: 'right',
					},
				],
				selectedRowKey: [], // 选择的数据Id
				selectedRow: [], //选择的整行数据
				isLoading: false, //加载中
				children: [],
			}
		},
		methods: {
			//获取数据
			getData() {
				this.selectedRowKey = [];
				this.isLoading = true;
				this.LayoutLevelServiceProxy.getPaged(
					this.filterText ? this.filterText : undefined,
					this.Sorting,
					this.request.maxResultCount,
					this.request.skipCount
				).finally(() => {
					this.isLoading = false;
				}).then(res => {
					// console.log(res);
					this.tableData = res.items;

					this.totalItems = res.totalCount;
					this.totalPages = Math.ceil(
						res.totalCount / this.request.maxResultCount
					);
					this.pagerange = [
						(this.pageNumber - 1) * this.request.maxResultCount + 1,
						this.pageNumber * this.request.maxResultCount,
					];
				})
			},
			//排序
			handleTableChange(pagination, filters, sorter) {
			    console.log(sorter);
			    if (sorter.order != undefined){
			        this.Sorting = sorter.field + " " + sorter.order.replace("end","");
			    }
			    else{
			        this.Sorting = undefined;
			    }
			    this.getData();
			},
			//新增
			createOrEdit(cases) {
				ModalHelper.create(CreatErrorCode, {
					isEdit: false,
					id: undefined
				}, {
					width: 500
				}).subscribe(res => {
					console.log(res)
					if(res){
						this.getData();
					}
					this.selectedRowKey = [];
				})
			},
			//编辑
			createOrEditMachineSupplier(record) {
				ModalHelper.create(CreatErrorCode, {
					isEdit: true,
					id: record.id
				}, {
					width: 500
				}).subscribe(res => {
					if(res){
						this.getData();
					}
					this.selectedRowKey = [];
				})
			},
			//删除
			deletetable(record) {
				this.$confirm(
				    {
				        title: this.l("ConfirmInformation"),
				        content: this.l("the_selected_option"),
				        cancelText: this.l('Cancel'),
				        okText: this.l('Ok'),
				        onOk: () => {
				            this.LayoutLevelServiceProxy.delete(record.id).then(res => {
				                this.getData();
				                this.$notification["success"]({
				                    message: this.l("SuccessfullyDeleted"),
				                });
				            })
				        }
				    })
			},
			//批量删除事件
			batchdelete() {
				if (this.selectedRowKey[0] <= 0 || this.selectedRowKey[0] == null) {
					abp.message.warn(this.l("PleaseSelectAtLeastOneItem"));
					return;
				}
				this.$confirm({
					title: this.l("ConfirmInformation"),
					content: this.l("the_selected_option？"),
					cancelText: this.l('Cancel'),
					okText: this.l('Ok'),
					onOk: () => {
						this.LayoutLevelServiceProxy.batchDelete(this.selectedRowKey).then(res => {
							this.getData();
							this.$notification["success"]({
								message: this.l("SuccessfullyDeleted"),
							});
						})
					}
				})
			},
			//行选择
			customRow: (record) => {
				return {
					on: {
						click: () => {
							_this.onSelect(record)
						}
					}
				}

			},
			//多选
			SelectAll(record, rows, crows) {
				if (record) {
					//更改表格数据勾选状态
					this.tableData.map(item => {
						item.checked = true;
					});
					rows.forEach((item, index) => {
						//判断是否存在
						let u = this.selectedRowKey.find(t => t == item.id);
						if (!u) {
							this.selectedRowKey = [...this.selectedRowKey, item.id];
						}
					});
				} else {
					//更改表格数据勾选状态
					this.tableData.forEach((item, index) => {
						item.checked = false;
						this.selectedRowKey = [];
					});
				}
			},
			// 重写复选框点击事件(不可删除)
			onSelect(record) {
				//debugger;
				record.checked = !record.checked;
				if (record.checked) {
					this.selectedRowKey.push(record.id);
					this.children.push(record.children);
				} else {
					this.selectedRowKey = _.difference(_this.selectedRowKey, [record.id]);;
					this.children = _.difference(_this.children, [record.children]);
				}
			},
			//搜索框点击事件
			filteronchange() {
				this.pageNumber = 1;
				this.request.skipCount = 0;
				this.getData();
			},
			//分页
			onChangePage(page, pageSize) {
				this.selectedRowKey = [];
				this.pageNumber = page;
				this.request.skipCount = (page - 1) * this.request.maxResultCount;
				this.getData();
			},
			//正常分页展示形式，显示（共有 {0} 条）
			// showTotalFun() {
			// 	console.log(this.totalItems);
			//     return this.l(
			//         "GridFooterDisplayText",
			//         this.totalItems
			//     );
			// },
			showSizeChange(current, size) {
				this.selectedRowKey = [];
				this.pageNumber = 1;
				this.request.maxResultCount = size;
				this.request.skipCount = (this.pageNumber) * this.request.maxResultCount - size;
				this.getData();
			},
		}
	}
</script>

<style>
	.pagination {
		margin: 10px auto;
		text-align: right;
	}
</style>
